@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Nanum+Gothic:wght@700&family=Noto+Sans+KR:wght@300;400;700&family=Poppins:wght@300&display=swap");

@font-face {
  font-family: "KoddiUDOnGothic";
  src: local("KoddiUDOnGothic"),
    url(../fonts/KoddiUDOnGothic-Bold.ttf) format("truetype");
  font-weight: bold;

  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "KoddiUDOnGothic";
  src: local("KoddiUDOnGothic"),
    url(../fonts/KoddiUDOnGothic-Regular.ttf) format("truetype");

  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Nanum Gothic", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  font-family: -apple-system, "KoddiUDOnGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.App {
  text-align: center;
}

.Nask-logo {
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #ffffff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainslider {
  width: 100%;
  /* eight: 48.466%; */
  /* height: calc((100vw -10) * 0.4866) px; */

  border: 0px solid #ff0000;
}
